<!-- 选择消息接收岗位或人员 -->
<template>
    <el-dialog class="add-reciever"
        :visible="viewIsReady"
        @close="handleClose"
        title="选择消息接收岗位或人员"
        width="560px">

        <el-form
            class="private"
            ref="form"
            @submit.prevent="handleSave"
            label-width="100px"
        >
            <el-form-item label="岗位" required>
                <el-select v-model="PB.post"
                    @change="handlePostChange"
                    class="width2">
                    <el-option
                        v-for="(o, i) in postList"
                        :key="i"
                        :label="o.name"
                        :value="o.id"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="员工" class="wrap">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="showText"
                    :fetch-suggestions="querySearch"
                    @select="handleSelect"
                    :disabled="!PB.post"
                    class="width2"
                ></el-autocomplete>
                <ul class="emp-list width2">
                    <li v-for="(item, index) in PB.empList"
                        :key="item.id"
                    >
                        <span class="label">{{ item.name }}</span>
                        <span class="el-icon-close"
                            @click.stop="handleEmpRemove(index)"
                        ></span>
                    </li>
                </ul>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary" :disabled="!PB.post">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'AddReceiver',
    props: ["list"],

    data () {
        return {
            viewIsReady: false,
            postList: [],
            showText: "",
            PB: {
                post : "",
                empList: []
            }
        }
    },

    watch: {
        list: {
            immediate: true,
            handler (item, old) {
                if (item && item !== old) {
                    this.viewIsReady = true;
                }
                else this.viewIsReady = false;
            }
        }
    },

    mounted () {
        this.fetchPostAll();
    },

    methods: {
        async fetchPostAll () {
			try {
				const res = await this.$axios({
					url: "/api/employee/queryPostAll",
					method: "post"
				})
				if (res.code === 2000) {
					const list = res.data.postList;
					this.postList = list;

				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取岗位配置参数出错", reason);
			}
        },

        handlePostChange () {
            this.PB.empList = []
        },

        async querySearch(queryString, cb) {
            this.PB.empId = 0;
            try {
				const res = await this.$axios({
					url: "/api/employee/queryLikeEmpList",
					method: "post",
					data: {
                        searchName: queryString,
                        type: 1,
                        postId: this.PB.post
                    }
				});

				if (res.code === 2000) {
                    cb(res.data.empList.map(emp => {
                        emp.value = [emp.name, emp.reginName, emp.postName].filter(Boolean).join(" - ")
                        return emp;
                    }));

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
			}
        },

        handleSelect(item) {
            this.PB.empList.push(item);
            this.showText = "";
        },

        handleEmpRemove (i) {
            this.PB.empList.splice(i, 1)
        },

        async handleSave () {
            this.$emit("change", {
                ...this.postList.find(({id}) => id == this.PB.post),
                staff: this.PB.empList
            });
            this.handleClose();
        },

        handleClose () {
            this.PB.post = "";
            this.PB.empList = [];
            this.showText = "";
            this.$emit("update:list", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .emp-list {
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 32px;
            margin-top: 8px;
            padding: 0 12px;
            background: #FBFBFB;

            &:first-child {
                margin-top: 16px;
            }

            .label {
                color: #909399;
            }

            .el-icon-close {
                color: #999999;
                cursor: pointer;

                &:hover {
                    color: #FD9A17;
                }
            }
        }
    }
</style>