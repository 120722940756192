import Tree from "@/utils/Tree";
import { treeProps } from "./config";
const testTree = new Tree([], treeProps.label);

export default {
    name: 'CustomerSource',

    data () {
        return {
            tree: [testTree],
            treeProps,
            allCategory: [], // 全部分类
        }
    },

    methods: {
        listToTree(list) {
            const tree = [];
            const map = {};

            for (const item of list) {
                map[item.id] = item;
                item.children = [];
            }

            for (const item of list) {
                if (item.parent) {
                    map[item.parent].children.push(item);
                } else {
                    tree.push(item);
                }
            }

            return tree;
        },


        async fetchData () {
			const loading = this.$loading();

            try {
                const res = await this.$axios({
                    url: "/api/sourceType/querySourceTypeList",
                    // method: "post"
                })
				if (res.code === 2000) {
                    // let _obj = res.data.sourceList.splice(13, 1);
                    // res.data.sourceList.splice(2, 0, _obj[0])
                    // console.log(_obj);
                    // console.log(res.data.sourceList);
                    // [res.data.sourceList[0], res.data.sourceList[1]] = [res.data.sourceList[1], res.data.sourceList[0]];

                    // const list = res.data.sourceList.map(it => {
                    //     it.parent = it.preid;
                    //     return it;
                    // })
                    // this.tree = new Tree(list, treeProps.label).children

                    // 上面的是之前大孙的方法 下面的新的方法

                    res.data.sourceList.map(it => {
                        it.parent = it.preid;
                    })
                    const tree = this.listToTree(res.data.sourceList);
                    this.tree = tree

                    loading.close();

				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();
			}
        }
    },

    created () {
		this.fetchData();
    }
}