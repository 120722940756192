<!-- 添加、编辑字典项 -->
<template>
    <el-dialog class="white-list-edit"
        :visible="viewIsReady"
        @close="handleClose"
        :title="titleText"
        width="500px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="100px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="值" prop="value">
                <el-input v-model.number="PB.value"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="功能描述" prop="remark">
                <el-input v-model="PB.remark"
                    type="textarea" rows="5"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="岗位" prop="positionIds" class="wrap">
                <el-checkbox-group v-model="PB.positionIds">
                    <el-checkbox
                        v-for="item in postList"
                        :key="item.id"
                        :label="item.id">
                        {{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'WhiteListEdit',
    props: ["item"],

    data () {
        return {
            viewIsReady: false,
            PB: {},
            // 验证规则
            rules: {
                value: { required: true, message: "请输入值", trigger: "blur" },
                remark: { required: true, message: "请输入功能描述", trigger: "blur" },
                positionIds: { required: true, message: "请选择岗位", trigger: "blur" }
            },
            postList: [],
        }
    },

    computed: {
        isUpdate () {
            return !!this.item?.id
        },
        titleText () {
            return this.isUpdate ? '编辑白名单' : '新增白名单'
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.PB = JSON.parse(JSON.stringify(item));
                this.viewIsReady = true;
            }
        }
    },

    methods: {
		// 获取岗位配置参数
		async fetchConfig () {
			if (this.postList.fine) return;
			try {
				const res = await this.$axios({
					url: "/api/employee/queryPostAll",
					method: "post"
				})
				if (res.code === 2000) {
					const list = res.data.postList;
					list.fine = true;
					this.postList = list;

				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取岗位配置参数出错", reason);
			}
		},

        async handleSave () {
            const loadingRef = this.$loading({
                target: ".white-list-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const {value, positionIds, remark} = this.PB;
                const res = await this.$axios({
                    url: "/api/white/saveWhite",
                    method: "post",
                    data: {
                        value, remark,
                        positionIds: positionIds.join(),
                        id: this.PB.id
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.viewIsReady = false;
            this.PB = {
                positionIds: []
            };
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        }
    },

    created () {
        this.fetchConfig()
    }
}
</script>

<style lang='scss' scoped></style>