<!-- 添加、编辑字典项 -->
<template>
    <el-dialog class="dictionary-edit"
        :visible="viewIsReady"
        @close="handleClose"
        :title="titleText"
        width="500px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="100px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="类型" prop="type">
                <span>{{ typeTxt }}</span>
                <!-- <el-select v-model="PB.type"
                    disabled>

                    <el-option
                        v-for="opt in typeEnum"
                        :key="opt.value"
                        :value="opt.value"
                        :label="opt.name"
                    ></el-option>
                </el-select> -->
            </el-form-item>

            <el-form-item label="名称" prop="name">
                <el-input v-model.trim="PB.name"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="排序" prop="sequence">
                <el-input v-model.number="PB.sequence"
                    class="width3"></el-input>
            </el-form-item>

            <el-form-item label="备注" prop="content">
                <el-input v-model="PB.content"
                    type="textarea" rows="5"
                    class="width3"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'DictionaryEdit',
    props: ["item", "typeEnum"],

    data () {
        return {
            viewIsReady: false,
            PB: {},
            // 验证规则
            rules: {
                type:     { required: true, message: "请选择类型", trigger: "blur" },
                name:     { required: true, message: "请输入名称", trigger: "blur" },
                sequence: { validator (r, val, c) {
                    if (typeof val === "number") c()
                    else if (!/~[1-9]\d?$/.test(val)) {
                        c("只能输入正整数")
                    }
                    else c()
                }, trigger: "blur"},
            }
        }
    },

    computed: {
        isUpdate () {
            return !!this.item?.id
        },
        titleText () {
            return this.isUpdate ? '编辑数据字典' : '新增数据字典'
        },
        typeTxt () {
            return this.typeEnum.find(
                it => it.value == this.PB.type)?.name || ""
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.PB = JSON.parse(JSON.stringify(item))
                this.viewIsReady = true;
            }
        }
    },

    methods: {
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".dictionary-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const {name, type, sequence, content} = this.PB;
                const res = await this.$axios({
                    url: "/api/configuration/addConfiguration",
                    method: "post",
                    data: {
                        name, type, sequence, content,
                        id: this.PB.id
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.viewIsReady = false;
            this.PB = {};
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped></style>