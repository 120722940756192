import forList from "@/mixin/forList";
import ViewParams from "./ViewParams.vue";

export default {
    name: "Logs",
    mixins: [forList],
    components: {ViewParams},

    data() {
        return {
            SP_: {
                searchValue: "",     // 账号
                searchStartTime: "", // 开始时间
                searchEndTime: "",   // 结束时间
                moduleName: "",   // 模块
            },

            detailData: null,
            detailTitle: "输入参数"

        };
    },

    methods: {
        async fetchData() {
            const loading = this.$loading();

            try {
                const res = await this.$axios({
                    url: "/api/operLogs/getlist",
                    method: "post",
                    data: {
                        ...{ // 参数不足会报错，，
                            searchValue: "",     // 搜索类容
                            searchStartTime: "", // 开始时间
                            searchEndTime: "",   // 结束时间
                            moduleName: "",   // 结束时间
                        },
                        ...this.SP
                    }
                });
				if (res.code === 2000) {
                    this.total = res.data.count;
                    const list = res.data.operList.map(item => {
                        if (item.in_param.length > 20) {
                            item.short_in_param = item.in_param.slice(0,30) + "..."
                        }
                        if (item.out_param.length > 20) {
                            item.short_out_param = item.out_param.slice(0,30) + "..."
                        }
                        return item;
                    });
                    this.tableData.splice(0, this.tableData.length, ...list)

                    loading.close();

				} else if (res.code !== 1003)
					throw res
                
            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },

        // 查看详细参数
        handleInParam(index) {
            this.detailTitle = "输入参数"
            const item = this.tableData[index];
            this.detailData = item.in_param
        },
        handleOutParam(index) {
            this.detailTitle = "输出参数"
            const item = this.tableData[index];
            this.detailData = item.out_param
        }
    }
};