import {nanoid} from "nanoid";

export const mockEnum = [
    { name: "请选择",   value: ""},
    { name: "币种",     value: 1 },
    { name: "付款方式", value: 2 },
    { name: "优惠方式", value: 3 }
]

// 函数创建一个空的菜单数据
export function initialPB () {
    return {
        nanoid: nanoid(),
        name: "",
        type: "",
        sequence: 99,
        content: "",
    };
}