import forList from "@/mixin/forList";
import DictionaryEdit from "./DictionaryEdit.vue";
import {mockEnum, initialPB} from "./config";


export default {
	name: "Dictionary",
	mixins: [forList],
	components: {DictionaryEdit},

	data() {
		return {
			SP_: {
				conType: "",
			},
			typeEnum: mockEnum,
			editingItem: null,
		};
	},

	watch: {
		"SP_.conType": function () {
			this.handleSearch()
		}
	},

	methods: {
		async fetchData() {
			const loading = this.$loading();

			this.fetchConfig();
			try {
				const res = await this.$axios({
					url: "/api/configuration/getConList",
					method: "post",
					data: {...this.extractParams()}
				});
				if (res.code === 2000) {
					this.total = res.data.count;
					this.tableData.splice(0, this.tableData.length,
						...res.data.conlist)

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();
			}
		},

		// 获取配置参数
		async fetchConfig () {
			if (this.typeEnum.fine) return;
			try {
				const res = await this.$axios({
					url: "/api/configuration/queryTypeParam",
					method: "post"
				})
				if (res.code === 2000) {
					const list = res.data.CompanyTypeEnum;
					list.fine = true;
					this.typeEnum = list;

				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取选项参数出错", reason);
			}
		},

		// 点击 "新增菜单"
		handleAddBtn() {
			if (!this.SP_.conType)
				return this.$message({
					message: "请选择一个类型",
					type: "warning"
				});
			const item = initialPB();
			item.type = this.SP_.conType;
			this.editingItem = item;
		},

		// 点击表格行操作按钮: 编辑 
		handleEdit(data) {
			this.editingItem = data;
		},

		// 点击表格行操作按钮: 删除
		async handleDelete(data) {
			let loadingRef;
			try {
				await this.$confirm("确定要删除吗", "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/configuration/delConfiguration",
					method: "post",
					data: {
						configid: parseInt(data.id)
					}
				})
				if (res.code === 2000) {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "删除失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		}
	},

	created () {
		this.auth.save = this.$hasAuthFor("api/configuration/addConfiguration");
		this.auth.delete = this.$hasAuthFor("api/configuration/delConfiguration");
	}
};