<!-- 添加、编辑字典项 -->
<template>
    <el-dialog
        :visible="viewIsReady"
        @close="handleClose"
        :title="title"
        width="1000px"
    >

        <div style="line-height: 1.8">
            {{ info }}
        </div>

        <div slot="footer">
            <el-button @click="handleClose" type="primary">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'ViewParams',
    props: ["params", "title"],

    data () {
        return {
            viewIsReady: false,
            info: {}
        }
    },

    watch: {
        params (params, old) {
            if (params && params !== old) {
                this.viewIsReady = true;
                this.info = params;
            }
        }
    },

    methods: {
        handleClose () {
            this.viewIsReady = false;
            this.info = "";
            this.$emit("update:params", null);
        }
    }
}
</script>

<style lang='scss' scoped></style>