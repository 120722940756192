import Tree from "@/utils/Tree";
import { treeProps } from "./config";
const testTree = new Tree([], treeProps.label);

export default {
    name: 'Organization',

    data () {
        return {
            tree: [testTree],
            treeProps,
            allCategory: [], // 全部分类
        }
    },

    methods: {
        async fetchData () {
			const loading = this.$loading();

            try {
                const res = await this.$axios({
                    url: "/api/employee/queryOrgAll",
                    // method: "post"
                })
				if (res.code === 2000) {
					this.tree = new Tree(res.data.orgList, treeProps.label).children

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();
			}
        }
    },

    created () {
		this.fetchData();
    }
}